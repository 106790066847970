<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GraficosIndex",
  components: {},
  data() {
    return {
      filter: {},
      listasForms: {},
    };
  },

  methods: {},

  async mounted() {},
};
</script>
